import { Injectable } from '@angular/core';
import { SplashMessageDialogComponent } from '@app/components/modals/splash-message-dialog/splash-message-dialog.component';
import { IncidentService } from '@app/providers/incident.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { dialogueWidths } from '@app/app.constants';

@Injectable({
	providedIn: 'root'
})
export class SplashMessagePopupService {
	constructor(private incidentSvc: IncidentService,
		private dialogService: DialogService) {
	}

	processSplashMessages() {
		return new Promise((resolve, reject) => {
			this.incidentSvc.getActiveAlerts()
				.then((result: Array<any>) => {
					if (result
					  && result.length > 0) {
						const msgs = [];
						result.forEach((sp) => {
							msgs.push(this.showSplashMessage(sp));
						});
						Promise.all(msgs)
							.then((_) => {
								resolve(true);
							});
					}
					else {
						resolve(true);
					}
				})
				.catch((_) => {
					reject(false);
				});
		});
	}

	showSplashMessage(alert: any) {
		this.splashMessage(alert, false);
	}

	previewSplashMessage(alert: any) {
		this.splashMessage(alert, true);
	}

	private splashMessage(alert: any, isPreview: boolean) {
		const modalInstance: DynamicDialogRef = this.dialogService.open(SplashMessageDialogComponent, {
			width: dialogueWidths.lg,
			styleClass: 'splashMessageModal',
			data: {
				splashMessage: alert
			}
		});

		let alertId: string = '';
		let alertResponseTypeId: string = '';
		return modalInstance.onClose
			.subscribe((response) => {
				alertId = response?.alert.alertId;
				alertResponseTypeId = response?.responseType;

				if (!isPreview) {
					this.incidentSvc.respondToAlert(alertId, alertResponseTypeId);
				}
			});
	}
}
