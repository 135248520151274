import { Component, OnInit } from '@angular/core';
import { PanicResolveReason } from '@app/shared/panic/panic-resolve-reason.model';
import { panicResolutionReasonTypes } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-panic-resolve-modal',
	templateUrl: './panic-resolve-modal.component.html',
	styleUrls: ['./panic-resolve-modal.component.scss']
})
export class PanicResolveModalComponent {
	resolveReasons: Array<PanicResolveReason> = [];
	resolveReasonTypes = panicResolutionReasonTypes;
	panicResolutionReasonTypeId: string = panicResolutionReasonTypes.clear;
	resolveComments: string = '';
	panicResolutionReasonTypes = panicResolutionReasonTypes;
	resolveCommentsMaxLength: number = 100;
	submitted: boolean = false;

	constructor(public translateService: TranslateService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.resolveReasons = [
			new PanicResolveReason(panicResolutionReasonTypes.clear, this.translateService.instant('panic.resolutionReasons.clear')),
			new PanicResolveReason(panicResolutionReasonTypes.custom, this.translateService.instant('panic.resolutionReasons.custom')),
			new PanicResolveReason(panicResolutionReasonTypes.mistake, this.translateService.instant('panic.resolutionReasons.mistake'))
		];

		this.dynamicDialogConfig.header = this.translateService.instant('panic.resolve');
	}

	dismiss() {
		this.dynamicDialogRef.close(false);
	}

	resolve() {
		this.submitted = true;

		if (this.panicResolutionReasonTypeId === this.panicResolutionReasonTypes.custom && !this.resolveComments) {
			return;
		}

		this.dynamicDialogRef.close({
			panicResolutionReasonTypeId: this.panicResolutionReasonTypeId,
			resolveComments: this.resolveComments
		});
	}
}
