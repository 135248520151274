<div class="modal-body">
    <div class="alert alert-danger mb-3" role="alert" *ngIf="this.panicResolutionReasonTypeId === this.panicResolutionReasonTypes.custom && !this.resolveComments && submitted">
        {{'panic.resolveCommentsRequired' | translate}}
    </div>
    <div class="mb-3">
        <label class="form-label">{{'panic.reason' | translate}}</label>
        <select id="panicResolutionReasonTypeId" class="form-select" [(ngModel)]="panicResolutionReasonTypeId">
            <option *ngFor="let reason of resolveReasons" [value]="reason.id">{{reason.name}}</option>
        </select>
    </div>

    <div class="mb-3">
        <label class="form-label">{{'panic.resolveComments' | translate}}</label>
        <textarea class="form-control"
                  id="resolveComments"
                  rows="3"
                  [(ngModel)]="resolveComments"
                  [required]="panicResolutionReasonTypeId === panicResolutionReasonTypes.custom"
                  [maxLength]="resolveCommentsMaxLength"></textarea>
        <div class="text-end">
            {{resolveCommentsMaxLength - resolveComments.length}} {{'panic.remaining' | translate}}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button id="cancelButton" type="button" class="btn btn-secondary" (click)="dismiss()">{{'common.cancel' | translate}}</button>
    <button id="resolveButton" type="button" class="btn btn-success" (click)="resolve()">{{'panic.resolve' | translate}}</button>
</div>
